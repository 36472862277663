import { initCheckbox, State } from './checkbox';
import { piString } from './pi';

const pi = piString().substr(2); // Not interested in "3."

export const init = (document: Document): void => {
  const [userInputEl, counterEl, errorsEl, restartBtn, enableHintsCheckbox, hintEl] = [
    document.querySelector('#user-input') as HTMLInputElement,
    document.querySelector('#counter') as HTMLElement,
    document.querySelector('#errors') as HTMLElement,
    document.querySelector('#restart') as HTMLButtonElement,
    document.querySelector('#enable-hints') as HTMLElement,
    document.querySelector('#hint') as HTMLElement,
  ];

  const [registerError, resetErrors] = errorCounter(errorsEl);
  const [registerProgress, resetDigits] = progressCounter(counterEl);
  const [enableRestartButton, disableRestartButton] = restartButtonToggle(restartBtn);
  const [hintsEnabled, onEnableHintsChange] = initCheckbox(enableHintsCheckbox, 'enable-hints', 'off');

  const resetInput = () => userInputEl.value = '';
  const setNextDigitHint = setHint(hintEl);

  const reset = (): void => {
    resetErrors();
    resetDigits();
    disableRestartButton();
    resetInput();
    setNextDigitHint(0);
  };

  userInputEl.addEventListener('keyup', (): void => {
    const candidate = userInputEl.value;

    if (candidate.length > 0) {
      enableRestartButton();
    }

    if (!pi.startsWith(candidate)) {
      registerError();

      userInputEl.value = userInputEl.value.substring(0, userInputEl.value.length - 1);
    } else {
      registerProgress(userInputEl.value.length);
    }

    setNextDigitHint(userInputEl.value.length);
  });

  restartBtn.addEventListener('click', () => reset());
  restartBtn.addEventListener('touchend', () => reset());

  const disableHints = () => hintEl.classList.add('hidden');
  const enableHints = () => hintEl.classList.remove('hidden');

  onEnableHintsChange((state: State): void => {
    switch (state) {
      case 'on':
        enableHints();
        break;
      case 'off':
        disableHints();
        break;
    }
  });

  onEnableHintsChange((): void => {
    userInputEl.focus();
  });

  hintsEnabled() ? enableHints() : disableHints();

  userInputEl.focus();
};

const errorCounter = (errorElement: HTMLElement) => {
  let errors = 0;

  const displayErrorCount = () => errorElement.innerText = `${errors}`;

  return [
    (): void => {
      errors++;

      displayErrorCount();
    },
    (): void => {
      errors = 0;

      displayErrorCount();
    },
  ];
};

const progressCounter = (counterElement: HTMLElement) => {
  const displayDigitCount = (digits: number) => counterElement.innerText = `${digits}`;

  return [
    displayDigitCount,
    () => displayDigitCount(0),
  ];
};

const restartButtonToggle = (button: HTMLElement) => [
  () => button.removeAttribute('disabled'),
  () => button.setAttribute('disabled', 'true'),
];

const setHint = (hintsEl: HTMLElement) => (digits: number): void => {
  (hintsEl.querySelector('#next-digit') as HTMLElement).innerText = pi.substr(digits, 1);
};
