export type State = 'on' | 'off';
type StateChangeHandler = (state: State) => void;

export const initCheckbox = (element: HTMLElement, storageKey: string, defaultState: State): [() => boolean, (handler: StateChangeHandler) => void] => {
  const changeHandlers: Array<(state: 'on' | 'off') => void> = [
    (state: State): void => storeState(state),
  ];

  const storeState = (state: State): void => {
    window.localStorage.setItem(storageKey, state);
  };

  const loadState = (): State => (window.localStorage.getItem(storageKey) || defaultState) as State;

  const initialState = loadState();

  storeState(initialState);

  const turnOn = (): void => {
    element.setAttribute('data-state', 'on');
    element.querySelector('button').classList.add('bg-blue-600');
    element.querySelector('button').classList.remove('bg-gray-200');
    element.querySelector('button span').classList.add('translate-x-5');
    element.querySelector('button span').classList.remove('translate-x-0');

    changeHandlers.forEach(h => h('on'));
  };

  const turnOff = (): void => {
    element.setAttribute('data-state', 'off');
    element.querySelector('button').classList.remove('bg-blue-600');
    element.querySelector('button').classList.add('bg-gray-200');
    element.querySelector('button span').classList.remove('translate-x-5');
    element.querySelector('button span').classList.add('translate-x-0');

    changeHandlers.forEach(h => h('off'));
  };

  const isEnabled = () => element.getAttribute('data-state') === 'on';

  const toggle = (): void => {
    isEnabled() ? turnOff() : turnOn();
  };

  element.addEventListener('click', (e: InputEvent): void => {
    toggle();
  });

  initialState === 'on' ? turnOn() : turnOff();

  return [
    isEnabled,
    (handler: (state: 'on' | 'off') => void): void => {
      changeHandlers.push(handler);
    }
  ];
};
